<template>
  <section class="section-1">
    <div class="content-w">
      <div class="content-row-1">
        <div class="text-col">
          <div class="text-content">
            <div class="typography-headline">
              <img src="../../../assets/images/home/ls-word.svg" alt="" />
              <p class="typography-headline-text">智慧卫生间整体解决方案</p>
            </div>
            <p class="typography-eyebrow">
              解决行业痛点，提升用户如厕体验，构建智慧卫生间生态！
            </p>
            <div class="line" />
          </div>
        </div>
        <div class="img-col">
          <img
            class="device-img"
            src="../../../assets/images/home/banner-device.svg"
            alt=""
          />
        </div>
      </div>
      <div class="content-row-2">
        <div class="content">
          <div class="item" v-for="(item, index) in cardList" :key="index">
            <div class="img-col">
              <img
                class="item-img"
                :src="
                  require('../../../assets/images/home/' + item.imgUrl + '.svg')
                "
                alt=""
              />
            </div>
            <div class="text-col">
              <div class="text-title">{{ item.title }}</div>
              <div class="text-content">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Slide1",
  components: {},
  data() {
    return {
      cardList: [
        {
          imgUrl: "card-1",
          title: "产品介绍",
          text: "生态链核心产品",
        },
        {
          imgUrl: "card-2",
          title: "产品优势",
          text: "自主研发，构建智慧厕所",
        },
        {
          imgUrl: "card-3",
          title: "小程序",
          text: "终端用户操作平台",
        },
        {
          imgUrl: "card-4",
          title: "我们的优势",
          text: "提升如厕体验+运维管理能力",
        },
      ],
    };
  },
};
</script>

<style scoped lang="less">
.section-1 {
  position: relative;
  width: 100vw;
  height: 100%;
  background: linear-gradient(180deg, #fefefe 0%, #e9ecf1 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  .content-w {
    margin: 0 auto;
    width: 1200px;
    height: 700px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    .content-row-1 {
      margin: -20px auto 0;
      height: 591px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .text-col {
        .text-content {
          height: 221px;
          margin-bottom: 50px;

          .typography-headline {
            display: flex;
            height: 75px;
            font-size: 48px;
            font-weight: bold;
            line-height: 75px;
            img {
              width: 188px;
              height: 81px;
            }
            .typography-headline-text {
              text-shadow: 0 2px 4px rgba(152, 152, 152, 0.5);
            }
          }

          .typography-eyebrow {
            margin-top: 40px;
            height: 40px;
            font-size: 20px;
            line-height: 40px;
          }

          .line {
            margin: 40px 0 0 4px;
            width: 45px;
            height: 4px;
            background: #fe690c;
          }
        }
      }

      .img-col {
        width: 280px;
        .device-img {
          width: 512px;
          height: 677px;
          position: relative;
          right: 102px;
        }
      }
    }

    .content-row-2 {
      margin-top: -8px;
      width: 100%;
      height: 100px;

      .content {
        width: 1200px;
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 99;
        .item {
          padding: 14px 0 18px 20px;
          box-sizing: border-box;
          width: 290px;
          height: 100%;
          background: #ffffff;
          box-shadow: 0 6px 5px 0 rgba(219, 219, 219, 0.5);
          border-radius: 2px;
          display: flex;
          align-items: center;
          .img-col {
            width: 68px;
            height: 68px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .text-col {
            margin-left: 4px;
            .text-title {
              height: 25px;
              font-size: 18px;
              font-weight: bold;
              line-height: 25px;
            }
            .text-content {
              margin-top: 3px;
              height: 20px;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
